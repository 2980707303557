import React from 'react';

const Header = () => {

    return (
        <header className="site-header header-main-layout-1 ast-primary-menu-enabled ast-logo-title-inline ast-hide-custom-menu-mobile ast-builder-menu-toggle-icon ast-mobile-header-inline sticky"
            id="masthead"
        >
            <div id="ast-desktop-header" className='' data-toggle-type="dropdown">
                <div className="ast-main-header-wrap main-header-bar-wrap ">
                    <div className="ast-primary-header-bar ast-primary-header main-header-bar site-header-focus-item">
                        <div className="site-primary-header-wrap ast-builder-grid-row-container site-header-focus-item ast-container">
                            <div className="ast-builder-grid-row ast-builder-grid-row-has-sides ast-builder-grid-row-no-center">
                                <div className="site-header-primary-section-left site-header-section ast-flex site-header-section-left">
                                    <div className="ast-builder-layout-element ast-flex site-header-focus-item">
                                        <div className="site-branding ast-site-identity">
                                            <span className="site-logo-img">
                                                <a href="http://tifinwealth.com/" className="custom-logo-link" rel="home" aria-current="page">
                                                    <img width="160" height="40" src="https://tifinwealth.com/wp-content/uploads/2022/10/TIFIN-Weath-Logo.png" className="custom-logo"
                                                        alt="TIFIN Wealth Logo"
                                                        sizes="(max-width: 160px) 100vw, 160px" />
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="site-header-primary-section-right site-header-section ast-flex ast-grid-right-section">
                                    <div className="ast-builder-menu-1 ast-builder-menu ast-flex ast-builder-menu-1-focus-item ast-builder-layout-element site-header-focus-item" data-section="section-hb-menu-1">
                                        <div className="ast-main-header-bar-alignment">
                                            <div className="main-header-bar-navigation"></div>
                                        </div>
                                    </div>
                                    <div className="ast-builder-layout-element ast-flex site-header-focus-item ast-header-button-1"
                                        data-section="section-hb-button-1">
                                        <div className="ast-builder-button-wrap ast-builder-button-size-">
                                            <a className="ast-custom-button-link" href="http://tifinwealth.com/contact-us/" target="_self">
                                                <div className="ast-custom-button">Contact Us</div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;